import http from '@/utils/request'

/**
 * 预约列表
 * @returns
 */
export const gameReservationList = () => {
  return http.get('/game-reservation/list')
}

/**
 * 预约详情
 * @returns
 */
export const gameReservationDetail = (params = {}) => {
  return http.get('/game-reservation/detail', params)
}

/**
 * 玩家预约
 * @returns
 */
export const submitGameReservation = (data = {}) => {
  return http.post('/game-reservation/reservation', data)
}
