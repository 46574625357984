<template>
  <div class="container">
    <div class="header" :style="{ 'background-image': 'url(' + detail.cover + ')' }">
      <div class="info">
        <div class="name">{{ detail.name }}</div>
        <div class="tags">
          <van-tag size="medium" v-for="(tag, tagIndex) in detail.tags" :key="tagIndex">{{ tag }}</van-tag>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="introduce">
        <div class="main-title">游戏介绍</div>
        <div class="intr-content">
          <div class="content" id="introduce-content" v-html="detail.introduce" :style="{ height: introduceHeight }"></div>
          <van-icon class="more" size="20" :name="showMore ? 'arrow-up' : 'arrow-down'" @click="changeMore()" />
        </div>
      </div>

      <div class="pictures">
        <div class="main-title">游戏视听</div>
        <van-swipe class="pictures-swipe" @change="changeSwipe" indicator-color="var(--app-active-color)">
          <van-swipe-item v-for="(video, index) in detail.videos" :key="index">
            <video class="game-video" height="200" :poster="video.poster" :src="video.src" preload="metadata" controls />
          </van-swipe-item>
          <van-swipe-item v-for="(picture, index) in detail.pictures" :key="index">
            <van-image @click="previewImg(index)" width="100%" height="195" :src="picture" class="picture"
          /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="reservation-btn">
<!--      <van-button v-if="detail.is_end && !detail.reservation_time" block size="large" round color="var(&#45;&#45;app-button-red-bg-color)" disabled-->
<!--        >预约结束</van-button-->
<!--      >-->
<!--      <van-button v-else-if="!detail.reservation_time" block size="large" round color="var(&#45;&#45;app-button-red-bg-color)" @click="reservation"-->
<!--        >一键预约</van-button-->
<!--      >-->
<!--      <van-button v-else block size="large" round color="var(&#45;&#45;app-button-bg-color)" @click="showReservationInfo()">{{-->
<!--        detail.activation ? '查看激活码' : '预约成功'-->
<!--      }}</van-button>-->
    </div>
    <van-dialog v-model:show="showReservationDialog" theme="round-button">
      <div class="van-dialog__message" v-if="detail.activation">
        您已成功预约游戏《{{ detail.name }}》，激活代码为：
        <h3 style="color: var(--app-active-color)">{{ detail.activation }}</h3>
      </div>
      <div class="van-dialog__message" v-else>您已成功预约游戏《{{ detail.name }}》，游戏开测前将通过微信消息通知您，请持续关注公众号，避免错过消息。</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'GameReservationDetail',
}
</script>
<script setup>
import { Tag, Image, Button, Icon, Swipe, SwipeItem, Dialog, ImagePreview } from 'vant'
import { ref, onMounted, nextTick } from 'vue'
import { gameReservationDetail, submitGameReservation } from '@/api/gameReservation'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import tool from '@/utils/tool'
import { bindSteamConfirm, bindMobileConfirm } from '@/utils/user'

const route = useRoute()
const store = useStore()
const VanImage = Image
const VanButton = Button
const VanTag = Tag
const VanIcon = Icon
const VanSwipe = Swipe
const VanSwipeItem = SwipeItem
const VanDialog = Dialog.Component
const VanImagePreview = ImagePreview.Component

const showReservationDialog = ref(false)
const showReservationData = ref()
const showReservationInfo = () => {
  showReservationDialog.value = true
}

onMounted(() => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
  initData()
})

const detail = ref({})
const initData = () => {
  gameReservationDetail({ id: route.query.id }).then(res => {
    detail.value = res.data.detail
  })
}

const reservation = () => {
  //判断是否绑定steamAccount
  if (!store.state.user.info.player_id) {
    bindSteamConfirm('一键预约')
    return false
  }

  //判断是否绑定手机号
  if (!store.state.player.extend.mobile) {
    bindMobileConfirm('一键预约')
    return false
  }

  let reservationOrigin = tool.data.get('reservationOrigin')
  submitGameReservation({ id: detail.value.id, origin: reservationOrigin }).then(res => {
    detail.value = res.data.detail
    showReservationDialog.value = true
  })
}

const previewImg = i => {
  ImagePreview({ images: detail.value.pictures, startPosition: i })
}

const changeSwipe = () => {
  let videos = document.getElementsByTagName('video')
  var videoList = Array.prototype.slice.call(videos)
  videoList.forEach(video => {
    if (!video.paused) video.pause()
  })
}

const introduceHeight = ref('')
const showMore = ref(false)
const orgHeight = ref('')
const changeMore = () => {
  showMore.value = !showMore.value
  const node = document.querySelector('#introduce-content')
  if (!orgHeight.value) orgHeight.value = node.clientHeight + 'px'
  nextTick(() => {
    introduceHeight.value = showMore.value ? node.scrollHeight + 'px' : orgHeight.value
    // console.log(showMore.value, introduceHeight.value, orgHeight.value)
  })
}
</script>

<style lang="scss" scoped>
.game-video {
  width: 100%;
  object-fit: cover;
}
.container {
  padding-bottom: 70px;
}
.header {
  width: 100vw;
  height: 235px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .info {
    position: absolute;
    padding: 5px;
    box-sizing: border-box;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-flow: column;
    .name {
      color: var(--app-main-title-color);
      font-size: 17px;
      margin-bottom: 3px;
      height: 23px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .van-tag {
        &:last-child {
          margin-right: 0;
        }
        margin-bottom: 3px;
        font-size: 10px;
        margin-right: 5px;
        font-weight: normal;
        color: var(--app-tag-text-color);
      }
      .van-tag--default {
        --van-tag-default-color: var(--app-tag-bg-color);
      }
    }
  }
}

.main {
  padding: 10px 20px;
  .main-title {
    font-size: 15px;
    padding-bottom: 8px;
    color: var(--app-text-color);
  }
  .introduce {
    .intr-content {
      width: 100%;
      position: relative;
      word-break: break-all;
      background: var(--app-main-bg-color);
      padding: 10px 20px;
      border-radius: 20px;
      box-sizing: border-box;
      color: var(--app-title-color);
      .content {
        height: 78px;
        overflow: hidden;
        font-weight: normal;
        line-height: 20px;
        font-size: 13px;
        transition: height 0.3s ease-in-out;
      }
      .more {
        position: absolute;
        bottom: -12px;
        left: 50%;
        padding: 2px 3px;
        transform: translateX(-50%);
        background: var(--app-main-bg-color);
        border-radius: 50%;
      }
    }
  }

  .pictures {
    margin-top: 10px;
    .pictures-swipe {
      border-radius: 5px;
      overflow: hidden;
      .picture {
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }
}
.reservation-btn {
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: calc(constant(safe-area-inset-bottom) + 15px);
  bottom: calc(env(safe-area-inset-bottom) + 15px);
  .van-button--round {
    border-radius: 18px !important;
  }
}
</style>
